class ZIDXAccountForgotPassword implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountForgotPasswordContainer";
    }
    getMatchURL(){
        return "/forgot-password";
    }
    render(){
        // console.log("forgot password");
    }
}
